require('./bootstrap');
require('./bwpublisher');

import AOS from 'aos';

if (document.querySelector('.testimonials-slider')) {
    require('./testimonials');
}

$(function () {
    // Overlay Toggle - Mobile menu
    $('#toggle').on('click', function () {
        $('main').css('z-index', 0);
        $(this).toggleClass('active');
        $('.overlay').toggleClass('open');

        if ($(this).hasClass('active')) {
            $('body').css('position', 'fixed');
            $('body').css('overflow', 'hidden');
            $('#announcement').hide();
            $('.burger-menu-text').hide();
        } else {
            $('body').css('position', 'relative');
            $('body').css('overflow', 'auto');
            $('#announcement').show();
            $('.burger-menu-text').show();
        }
    });

    // Animate on Scroll
    AOS.init({
        once: true,
        mirror: false,
    });

    // Scroll to Anchor
    let $scroll = window.location.hash
    if ($scroll) {
        if ($($scroll).length) {
            setTimeout(function () {
                jumpTo($scroll);
            }, 100);
        }
    }

    // Logo Slider
    $('.logo-slider').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        fade: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
            },
        }, {
            breakpoint: 900,
            settings: {
                slidesToShow: 3,
            },
        }, {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
            },
        }],
    });
});

function jumpTo(target, options) {
    $(target)[0].scrollIntoView(options);
}
