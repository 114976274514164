const slider = document.querySelector('.testimonials-slider');
const sliderRight = document.querySelector('.slider-arrow-right');
const sliderLeft = document.querySelector('.slider-arrow-left');

let mouseDown = false;
let startX, scrollLeft;

const startDragging = (e) => {
  mouseDown = true;
  startX = e.pageX - slider.offsetLeft;
  scrollLeft = slider.scrollLeft;
};

const stopDragging = (e) => {
  mouseDown = false;
};

const move = (e) => {
  e.preventDefault();

  if (! mouseDown) {
    return;
  }

  const x = e.pageX - slider.offsetLeft;
  const scroll = (x - startX) * 1.3;
  slider.scrollLeft = scrollLeft - scroll;

  checkArrowVisibility();
};

const checkArrowVisibility = () => {
  if (slider.scrollLeft <= 0) {
    sliderLeft.style.visibility = 'hidden';
  } else {
    sliderLeft.style.visibility = 'visible';
  }

  if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth) {
    sliderRight.style.visibility = 'hidden';
  } else {
    sliderRight.style.visibility = 'visible';
  }
};

slider.addEventListener('mousemove', move, false);
slider.addEventListener('mousedown', startDragging, false);
slider.addEventListener('mouseup', stopDragging, false);
slider.addEventListener('mouseleave', stopDragging, false);

sliderRight.addEventListener('click', () => {
  slider.scrollLeft += 1100;
  checkArrowVisibility();
});

sliderLeft.addEventListener('click', () => {
  slider.scrollLeft -= 1100;
  checkArrowVisibility();
});

checkArrowVisibility();
